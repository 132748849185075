<template>
<div>
<bar ref="bar" close="true" step="Traducir" :help="instructions"/>

<v-content>
  <message/>
  <div>
  <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <v-flex v-for="word in solved" :key="word.id" xs12 md6>

        <v-layout align-center justify-center row fill-height>
          <v-flex xs5>
            <v-btn class="caption font-weight-bold" block text>{{word.text}}</v-btn>
          </v-flex>
          <v-flex xs2>
            <v-btn block text @click="fix(word.id,word.text)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs5>
            <v-btn class="caption font-weight-bold" block text>{{word.trans}}</v-btn>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>
  </v-container>

  <dictionary :random="true" :redirect="$store.state.step.learnWords.redirect" :order="$store.state.step.learnWords.order"/>

  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">Corregir palabra</v-card-title>
      <v-card-text>¿No estás de acuerdo con la traducción de {{text}}? Escribe la palabra correcta en castellano y pulsa enviar</v-card-text>
      <v-card-actions>
        <v-textarea
          :label="text+' realmente significa:'"
          outlined
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          no-resize
          outline
          v-model="fixedWord"
          @keyup.enter.native="send"
        ></v-textarea>
      </v-card-actions>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="warning" text @click="dialog = false">Cancelar</v-btn>
        <v-btn color="success" @click="send()">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
       
  </div>
</v-content>
</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import dictionary from '../components/studyUtils/Dictionary.vue'

export default {
  name: 'learn-words',
  data() {
    return {
      solved : [],
      dialog: false,
      id: false,
      text: '',
      fixedWord: '',
      total: 0,
      instructions: {
        title : 'Traducir en Modo Avanzado',
        text : 'Relaciona las palabras con su traducción.',
        titleSecondary : 'El modo avanzado',
        textSecondary : 'En el modo avanzado realizarás el ejercicio sobre el poema entero. Es prácticamente imposible superar el modo avanzado en un sólo intento, concéntrate en acertar el máximo de palabras cada vez, todas las palabras acertadas quedarán guardadas para otros intentos que realices después o incluso otro día.',
        key : 'instructionsAdvancedLearnWords'
      }
    }
  },
  components: {
    message,
    bar,
    dictionary
  },
  beforeMount(){
    if (this.$store.state.step.learnWords.redirect === 'play-basic') {
      this.instructions.title = 'Traducir en Modo Básico'
      this.instructions.titleSecondary = 'El modo básico'
      this.instructions.textSecondary = 'En el modo básico realizarás el ejercicio completo de un sólo verso, cuando lo completes pasarás al verso siguiente. De esta manera irás aprendiendo los versos del poema uno a uno. No te preocupes si no aciertas el verso a la primera, las palabras acertadas se guardarán para que lo sigas intentando.'
      this.instructions.key = 'instructionsBasicLearnWords'
    }
  },
  mounted(){
    this.total = this.$store.state.unknownWords.length
    let that = this
    this.$refs.bar.setProgress(0,this.total)
    this.$root.$on('solvedWord',function(data){
      that.$refs.bar.setProgress(that.total - that.$store.state.unknownWords.length,that.total)
      that.solved.push( { 
        id: data.id,
        text: data.text,
        trans: data.trans
      })
    })
  },
  beforeDestroy: function(){
    this.$root.$off('solvedWord')
  },
  methods: {
    fix : function(id,text){
      this.id = id
      this.text = text
      this.dialog = true
    },
    send : function(){
      this.$http.post(this.$store.state.urlApi+'step/'+this.$store.state.step.id+'/fix', { 
        id : this.id,
        word: this.fixedWord
      },
        { headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+this.$store.state.accessToken
      }})
      this.fixedWord = ''
      this.dialog = false
      this.$root.$emit('changeSnackbar', { show : true , text : 'Gracias por tu colaboración, valoraremos la corrección de la palabra', timeout: 6000, type: 'success' } )
    }
  }
}
</script>

<style>

</style>
